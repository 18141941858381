import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function ActiveProjectLoader() {
  return (
    <SkeletonTheme baseColor="#edf2f9" highlightColor="#dde6f0" duration={0.7}>
      <div
        className="flex"
        style={{
          justifyContent: "space-between",
          borderRadius: "10px",
          padding: "30px 30px",
          background: "#f3f6f8",
          flexDirection: "column",
        }}
      >
        <Skeleton
          style={{
            backgroundColor: "#edf2f9",
            display: "flex",
            height: "1rem",
            width: "25%",
            borderRadius: "5px",
          }}
        />
        <div style={{ position: "relative" }}>
          <Skeleton
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              marginTop: "5px",
            }}
          />
          <Skeleton
            style={{
              position: "absolute",
              top: 20,
              left: 50,
              backgroundColor: "#edf2f9",
              display: "flex",
              height: "2rem",
              width: "90%",
              borderRadius: "5px",
              marginTop: "-10px",
            }}
          />
        </div>

        <div style={{ position: "relative" }}>
          <Skeleton
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              marginTop: "5px",
            }}
          />
          <Skeleton
            style={{
              position: "absolute",
              top: 20,
              left: 50,
              backgroundColor: "#edf2f9",
              display: "flex",
              height: "2rem",
              width: "90%",
              borderRadius: "5px",
              marginTop: "-10px",
            }}
          />
        </div>

        <div style={{ position: "relative" }}>
          <Skeleton
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              marginTop: "5px",
            }}
          />
          <Skeleton
            style={{
              position: "absolute",
              top: 20,
              left: 50,
              backgroundColor: "#edf2f9",
              display: "flex",
              height: "2rem",
              width: "90%",
              borderRadius: "5px",
              marginTop: "-10px",
            }}
          />
        </div>

        <div style={{ position: "relative" }}>
          <Skeleton
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              marginTop: "5px",
            }}
          />
          <Skeleton
            style={{
              position: "absolute",
              top: 20,
              left: 50,
              backgroundColor: "#edf2f9",
              display: "flex",
              height: "2rem",
              width: "90%",
              borderRadius: "5px",
              marginTop: "-10px",
            }}
          />
        </div>

        <div style={{ position: "relative" }}>
          <Skeleton
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              marginTop: "5px",
            }}
          />
          <Skeleton
            style={{
              position: "absolute",
              top: 20,
              left: 50,
              backgroundColor: "#edf2f9",
              display: "flex",
              height: "2rem",
              width: "90%",
              borderRadius: "5px",
              marginTop: "-10px",
            }}
          />
        </div>

        <div style={{ position: "relative" }}>
          <Skeleton
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              marginTop: "5px",
            }}
          />
          <Skeleton
            style={{
              position: "absolute",
              top: 20,
              left: 50,
              backgroundColor: "#edf2f9",
              display: "flex",
              height: "2rem",
              width: "90%",
              borderRadius: "5px",
              marginTop: "-10px",
            }}
          />
        </div>
      </div>
    </SkeletonTheme>
  );
}

export default ActiveProjectLoader;
