import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function OverViewLoader() {
  return (
    <SkeletonTheme baseColor="#edf2f9" highlightColor="#dde6f0" duration={0.7}>
      <div
        className="flex"
        style={{
          justifyContent: "space-between",
          borderRadius: "10px",
          padding: "30px 30px",
          background: "#f3f6f8",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Skeleton
          style={{
            backgroundColor: "#edf2f9",
            display: "flex",
            height: "2rem",
            width: "20%",
            borderRadius: "5px",
          }}
        />
        <Skeleton
          style={{
            backgroundColor: "#edf2f9",
            display: "flex",

            width: "8%",
            borderRadius: "50px",
            position: "absolute",
            right: 20,
            top: 30,
          }}
        />
        <Skeleton
          style={{
            backgroundColor: "#edf2f9",
            display: "flex",

            width: "15%",
            borderRadius: "5px",
          }}
        />
        <Skeleton
          style={{
            backgroundColor: "#edf2f9",
            display: "flex",
            height: "2rem",
            width: "100%",
            borderRadius: "5px",
          }}
        />
        <Skeleton
          style={{
            backgroundColor: "#edf2f9",
            display: "flex",
            height: "1.5rem",
            width: "15%",
            borderRadius: "5px",
          }}
        />
        <Skeleton
          style={{
            backgroundColor: "#edf2f9",
            display: "flex",

            width: "25%",
            borderRadius: "5px",
          }}
        />
        <Skeleton
          style={{
            backgroundColor: "#edf2f9",
            display: "flex",

            width: "55%",
            borderRadius: "5px",
          }}
        />
        <Skeleton
          style={{
            backgroundColor: "#edf2f9",
            display: "flex",
            height: "1.5rem",
            width: "15%",
            borderRadius: "5px",
          }}
        />
        <Skeleton
          style={{
            backgroundColor: "#edf2f9",
            display: "flex",
            height: "15rem",
            width: "100%",
            borderRadius: "5px",
          }}
        />
        <Skeleton
          style={{
            backgroundColor: "#edf2f9",
            display: "flex",
            height: "3rem",
            width: "15%",
            borderRadius: "10px",
            position: "absolute",
            right: 30,
            bottom: 10,
          }}
        />
      </div>
    </SkeletonTheme>
  );
}

export default OverViewLoader;
