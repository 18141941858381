import React from "react";
import { useStyles } from "../Section.theme";
function Dropdown({ value, change, styles, choices = [] }) {
  const classes = useStyles();

  return (
    <select
      className={classes.inputElement}
      value={value}
      onChange={(e) => change(e.target.value)}
      style={styles}
    >
      <option disabled selected>
        --Select--
      </option>
      {choices.map((choice) => {
        return <option value={choice}>{choice}</option>;
      })}
    </select>
  );
}

export default Dropdown;
