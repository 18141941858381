import React from "react";
import { Box, Stack, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Element from "./../Form/Element";
import { inputType } from "../../utils/enum";
import Dropdown from "../Form/Dropdown";

const KanbanModal = () => {
  return (
    <>
      <Box sx={{ marginTop: "20px" }}>
        <Element eletype={inputType.input} label="Project" />
      </Box>

      <Box sx={{ marginTop: "20px" }}>
        <Element eletype={inputType.input} label="Description" />
      </Box>

      <Box sx={{ margin: "30px 0 10px 0" }}>
        <FormControl fullWidth>
          <Dropdown label="Nature of work" />
        </FormControl>
      </Box>

      <Box sx={{ margin: "30px 0 10px 0" }}>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Element eletype={inputType.input} label="Due Date" />
          <Element eletype={inputType.input} label="Price" />
        </Stack>
      </Box>
    </>
  );
};

export default KanbanModal;
