import React from "react";
import ReactDatePicker from "react-datepicker";

import { useStyles } from "../Section.theme";
function DatePicker({ value, change, styles }) {
  const classes = useStyles();

  function handleChange(date) {
    change(date);
  }

  const val = !value ? null : new Date(value);

  return (
    <ReactDatePicker
      dateFormat="dd/MM/yyyy"
      selected={val}
      onChange={handleChange}
      customInput={<input className={classes.inputElement} style={styles} />}
    />
  );
}

export default DatePicker;
